import moment from 'moment';

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
    $('.header-wrapper').slideToggle()
    $(this).toggleClass('active')
  })
    
  $('.menu-item-has-children a').click(function(){
    $(this).preventDefault
    $('.sub-menu').slideToggle()
  })

  // homepase hero slider
  $('.homepage-hero-slider').slick({
    prevArrow: '<button type="button" class="slick-prev" aria-label="previous"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="next"><i class="fas fa-chevron-right"></i></button>',
    autoplay: true,
    autoplaySpeed: 5000,
  });

  // homepase hero slider
  $('.history-slider').slick({
    prevArrow: '<button type="button" class="slick-prev" aria-label="previous"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="next"><i class="fas fa-chevron-right"></i></button>',
    slidesToShow: 5,
    appendArrows: '.history-arrows',
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1079,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  // Get youtube thumbnails for history slider
  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  // Get youtube thumbnails
  const youtubeHistoryItems = document.querySelectorAll('.youtube-source')
  youtubeHistoryItems.forEach(item => {
    const itemLink = item.href
    const itemYoutubeId = youtube_parser(itemLink)
    const itemYoutubeThumb = `<img src="//img.youtube.com/vi/${itemYoutubeId}/0.jpg">`
    const currentThumb = item.querySelector('.image-youtube')
    currentThumb.innerHTML = itemYoutubeThumb
  })

  // Player
  $( '.player button' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).find( 'i' ).toggle();

		const audios = $( '.player audio' );
		const audio = $( this ).next( 'audio' )[0];

		audios.each( function ( index, item ) {
			if ( $( item )[0] !== audio ) {
				$( item )[0].pause();
				$( item ).prev( 'button' ).find( '.fa-pause' ).css( 'display', 'none' );
				$( item ).prev( 'button' ).find( '.fa-play' ).css( 'display', 'inline-block' );
			}
		} );

		if ( audio.paused ) {
			audio.play();
		} else {
			audio.pause();
		}
	} );

  $('.lyrics .trigger').on('click', function(e){
    e.preventDefault()
    $(this).next('.lyrics-popup').addClass('active')
  })

  $('.lyrics-popup .close').on('click', function(e){
    e.preventDefault()
    $(this).parent().parent().parent().toggleClass('active')
  })

  $('.lyrics-popup .close-times').on('click', function(e){
    e.preventDefault()
    $(this).parent().parent().parent().toggleClass('active')
  })
  

  // Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Tracy%20Lawrence/events?app_id=45PRESS_tracy-lawrence',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
        const events = $( '#events' );
        let html = '';
        if ( data.length ) {
            for ( let event of data ) {
                const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                html += '<div class="event" data-bit-id="' + event.id + '">';
                html += '<div class="event-date date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                html += '<div class="event-venue venue">' + event.venue.name + '</div>';
                html += '<div class="event-location location">' + event.venue.city + ' ' + event.venue.region + ', ' + event.venue.country + '</div>';
                html += '<div class="buttons">';
                html += '<a href="' + event.url + '" target="_blank" class="btn btn-red">Tickets</a>';
                if (event.offers[1]){
                  html += '<a href="' + event.offers[1].url + '" target="_blank" class="btn btn-red">VIP</a>';
                }
                html += '</div>';
                html += '</div>';
            }
            events.html( html );
        } else {
            events.html( 'No upcoming events.' );
        }
    }
  });


});